import styles from './App.module.scss';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import HomePage from '../components/HomePage/HomePage';

function App() {
  return (
    <div className={styles.App}>
      <Header />
      <HomePage />
      <Footer />
    </div>
  );
}

export default App;
